import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`15:00 amrap (moderate intensity) of:`}</p>
    <p>{`15 Strict Pushups`}</p>
    <p>{`5 Strict Chin Ups`}</p>
    <p>{`5 Strict Bar Dips`}</p>
    <p>{`15 Banded Bicep Curls`}</p>
    <p>{`then,`}</p>
    <p>{`16 Rounds of Tabata Situps (:20 on, :10 off)`}</p>
    <p>{`then,`}</p>
    <p>{`Upperbody Mobility work`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      